import React from "react";
import axios from "axios";
import { API_URL, RedirectUrl } from "./config";
import { Redirect } from "react-router-dom";
import { ai } from "./ApplicationInsights";
import "./App.css";
import { createBrowserHistory } from "history";
import { withAITracking } from "@microsoft/applicationinsights-react-js";


const history = createBrowserHistory({ basename: "" });
ai.initialize({ history: history });

console.log('Hostname');
console.log(window.location.hostname);

class App extends React.Component {
  state = {
    error: false,
    isLoaded: true,
  };

  componentDidMount = () => {
    console.log(window.location.pathname)
    this.getRedirectUrl();
  };
  // FETCHING DATA
  getRedirectUrl = async () => {
    let url = API_URL + "RedirectUrl?url=" + window.location.pathname;
    console.log("url");
    console.log(url);

    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
        },
      })
      .then((result) => {
        console.log("result");
        console.log(result);
        console.log(result.data);
        console.log(result.data.redirectUrl);
        if(result.data.statusCode === 200) {
          console.log(result.data.newUrl)
          return window.location.replace(result.data.redirectUrl);
   
        }
        
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          isLoaded: false,
          error: true,
        });
      });
  };

  render() {
    return <></>;
  }
}

export default withAITracking(ai.reactPlugin, App);
