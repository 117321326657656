let API_URL;
let RedirectUrl = "https://sharepoint.chla.usc.edu"



if (process.env.NODE_ENV === 'development') {
	API_URL = "https://func-appdev-url-redirect-prod-001.ase-eapps-prod-001.p.azurewebsites.net/api/"
} else if (process.env.NODE_ENV === 'production') {
	API_URL = "https://func-appdev-url-redirect-prod-001.ase-eapps-prod-001.p.azurewebsites.net/api/"
}

export {API_URL,RedirectUrl};