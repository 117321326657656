import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { ConnectionStringParser}from '@microsoft/applicationinsights-common';

class AppInsightTrack {

    constructor() {
        this.reactPlugin = new ReactPlugin();
    }

    initialize(reactPluginConfig) {
        let INSTRUMENTATION_KEY = '1c4a5417-2f00-4aa6-a391-19522581b66e'; // Enter your instrumentation key here
        
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: INSTRUMENTATION_KEY,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: reactPluginConfig
                }
            }
        });
        // let client = this.appInsights.TelemetryClient();
        
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();

    }
}

export let ai = new AppInsightTrack();